import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { StylesToBase } from "components/atoms/typography/typography.styled";
import { StylesToButton } from "components/atoms/typography/typography.styled";
import { Icon } from "components/atoms/icon/icon.component";

export const StyledButtonBack = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    background-color: ${({ theme }) => theme.gray?.[100] };
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledButtonIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.default };
`;

export const StyledStepList = styled.div`
    display: flex;
    justify-content: center;
    gap: 32px;
`;

export const StyledStepItem = styled.div<{active: boolean}>`
    max-width: 150px;
    padding: ${spacing(24)}  ${spacing(4)};
    color:  ${({ theme, active }) => active ? theme.text?.default : theme.gray?.[500] };
    text-align: center;
`;

export const StyledStepNumber = styled.div<{active: boolean}>`
    ${StylesToBase};
    margin: 2px auto 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:  ${({ theme }) => theme.bg?.page };
    background-color: ${({ theme, active }) => active ? theme.purple?.[800] : theme.gray?.[500] };
`;

export const StyledItemForm = styled.div<{$hide?: boolean}>`
    padding: ${({ $hide }) => $hide ? 0 : spacing(12)} 0;
    max-height: ${({ $hide }) => $hide ? 0 : 400}px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
`;

export const StyledItemText = styled(Typography)`
    color:  ${({ theme }) => theme.gray?.[800] };
    margin-bottom: 8px;
`;

export const StyledButtonBox = styled.div`
    padding: ${spacing(16)}  0;
`;

export const StyledForm = styled.form`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledChoiceList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
`;

export const StyledChoiceError = styled(Typography)<{error?: boolean}>`
    color: ${({theme}) => theme.red?.[800]};
    max-height: ${({ error }) => error ? 25 : 0}px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
`;

export const StyledCheckboxWrap = styled.div`
    padding: 12px 0;
`;

export const StyledNextStepButton = styled.button`
    ${StylesToButton};
    width: 100%;
    border: none;
    padding: ${spacing(12)};
    color: ${({theme}) => theme.green?.[1400]};
    background-color: ${({theme}) => theme.green?.[800]};
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    transition: 0.3s;
    &:hover {
        background-color: ${({ theme}) => theme.green?.[900]};
    }
    &:active {
        background-color: ${({ theme}) => theme.green?.[1000]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.green?.[1000]};
    }
    &:disabled {
        pointer-events: none;
        background-color: ${({ theme}) => theme.green?.[400]};
        color: ${({ theme}) => theme.gray?.[1000]};
    }
`;
