import React from "react";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes, ButtonSize } from "components/atoms/button-link/button-link.types";
import {
    StyledTextDocument,
    StyledInfoList,
    StyledInfoItem,
    StyledInfoNum,
} from "./text-documents.styled";

export interface IProps {
    closeModal: () => void;
}

interface IInfo {
    text: string;
    key: string;
}

export const TextDocumentModal: React.FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation();

    const infoList: IInfo[] = [
        {
            text: __("infoDocuments.listText1"),
            key: "document-visible",
        },
        {
            text: __("infoDocuments.listText2"),
            key: "ensure",
        },
        {
            text: __("infoDocuments.listText3"),
            key: "no-copies",
        },
        {
            text: __("infoDocuments.listText4"),
            key: "solid-colored",
        },
    ];

    return (
        <StyledTextDocument closeModal={closeModal}>
            <Typography variant={TypographyTypes.H3}>{__("infoDocuments.textUpload")}</Typography>
            <StyledInfoList>
                {infoList.map((item, index) => (
                    <StyledInfoItem key={item.key}>
                        <StyledInfoNum>{index+1}</StyledInfoNum>
                        <Typography variant={TypographyTypes.DEFAULT}>{item.text}</Typography>
                    </StyledInfoItem>
                ))}
            </StyledInfoList>
            <ButtonLink
                variant={ButtonTypes.SECONDARY}
                size={ButtonSize.DEFAULT}
                onClick={closeModal}
                text={__("components.close")}
            />
        </StyledTextDocument>
    );
};
