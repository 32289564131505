import { css, DefaultTheme, ThemedCssFunction } from "styled-components";
import { conversionSize, findStyle } from "helpers/conversion-style";
import { typographyStyle } from "lib/tokens/ui.styled";

/* Media */
export const sizes = {
    m: 360,
    xxs: 400,
    xs: 480,
    sm: 768,
    md: 1024,
    mmd: 1180,
    tablet: 1280,
    lg: 1600,
};

type TSizes = typeof sizes;

type TMedias = {
    [Property in keyof TSizes]: ThemedCssFunction<DefaultTheme>;
} &
    {
        [Property in keyof TSizes as `${Property}Max`]: ThemedCssFunction<DefaultTheme>;
    };

const keys = Object.keys(sizes) as Array<keyof TSizes>;

export const media = keys.reduce((acc, label) => {
    return {
        ...acc,
        [label]: (...args: Parameters<ThemedCssFunction<DefaultTheme>>) => css`
            @media (min-width: ${sizes[label] / 16}em) {
                ${css(...args)}
            }
        `,
        [`${label}Max`]: (
            ...args: Parameters<ThemedCssFunction<DefaultTheme>>
        ) => css`
            @media (max-width: ${(sizes[label] - 1) / 16}em) {
                ${css(...args)}
            }
        `,
    };
}, {} as TMedias);


/* Font size */
export const fontSizesTypes = {
    H1: "h1",
    H2: "h2",
    H3: "h3",
    H4: "h4",
    H5: "h5",
    H6: "h6",
    HUGE: "huge",
    LARGE: "large",
    BASE: "base",
    SMALL: "small",
    BUTTON: "button",
};

type TFonts = typeof fontSizesTypes;

const sizesKeys = Object.keys(fontSizesTypes) as Array<keyof TFonts>;

export const fontSizes = sizesKeys.reduce((acc, label) => {
    return {
        ...acc,
        [label]: () => {
            const sizer = 0.0665;
            const sizerDesktop = 0.0555;
            const min = conversionSize(findStyle(`fontSizes.mobile.${fontSizesTypes[label]}.value`, typographyStyle));
            const minSize = Number(min) * sizer + "em";
            const max = conversionSize(findStyle(`fontSizes.desktop.${fontSizesTypes[label]}.value`, typographyStyle));
            const maxSize = Number(max) * sizerDesktop + "em";
            return css`
                font-size: ${maxSize}; 
                font-size: min(max(${minSize}, 2.5vw), ${maxSize}); 
                font-size: clamp(${minSize}, 2.5vw, ${maxSize});
            `;
        },
    };
}, {} as TFonts);
