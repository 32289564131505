import styled from "styled-components";
import { Modals } from "../modals.component";
import { StyledTitleBox } from "../modals.styled";
import { Typography } from "components/atoms/typography/typography.component";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import { media } from "lib/styled-components/variables";

export const StyledInfoDocuments = styled(Modals)`
    max-width: 570px;
    width: 100%;
    color: ${({ theme }) => theme.text?.muted};
    ${StyledTitleBox} {
        display: none;
    }
    ${media.md`
        height: 530px;
        border-radius: 24px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        overflow-y: auto;
    `}
`;

export const StyledInfoBox = styled.div`
    padding: 40px 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${media.mdMax`
        padding-top: 32px;
    `}
`;

export const StyledLink = styled(CustomLink)`
    color: ${({ theme }) => theme.purple[800]};
`;

export const StyledInfoTitle = styled(Typography)`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.text?.default};
`;

export const StyledInfoDescription = styled(Typography)`
    margin-bottom: 40px;
    ${media.mdMax`
        margin-bottom: 32px;
    `}
`;

export const StyledInfoList = styled.ul`
    margin: 8px 0 0;
    padding: 0;
    color: ${({ theme }) => theme.text?.default};
`;

export const StyledInfoItem = styled.li`
    padding: 12px 0;
    margin: 0;
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px;
`;

export const StyledInfoNum = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #8050F1;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.base.white};
`;

export const StyledButtonList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    justify-content: end;
    padding-bottom: 16px;
`;

export const StyledMoreLink = styled(CustomLink)`
    color: ${({ theme }) => theme.purple[800]};
    display: inline-flex;
    margin: 12px auto;
`;
