import styled from "styled-components";
import { StyledTitleBox } from "../modals.styled";
import { Modals } from "../modals.component";

export const StyledTextDocument = styled(Modals)`
    width: 100%;
    padding: 32px 16px 16px;
    max-width: 500px;
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledInfoList = styled.ul`
    padding: 24px 4px 32px;
    margin: 0;
    display: flex;
    flex-direction: column;
`;

export const StyledInfoItem = styled.li`
    padding: 12px 0;
    display: grid;
    align-items: start;
    grid-template-columns: 24px 1fr;
    gap: 8px;
`;

export const StyledInfoNum = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.base.white};
    background-color: #8050F1;
`;
