import styled, { css, keyframes } from "styled-components";
import { Modals } from "../modals.component";
import { StyledTitleBox } from "../modals.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";
import { media } from "lib/styled-components/variables";

interface IProps {
    $active: boolean;
}

const fadeIn = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(-100px);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        transform: translateY(-100px);
        opacity: 1;
    }

    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
`;

const lineMove = keyframes`
    0% {
        right: 0;
    }

    100% {
        right: 100%;
    }
`;

export const StyledNotification = styled(Modals)<IProps>`
    max-width: 525px;
    padding: ${spacing(16)} ${spacing(48)} ${spacing(16)} ${spacing(16)};
    background-color: ${({ theme }) => theme.components.toast__success_bg};
    border: 1px solid ${({ theme }) => theme.components.toast__success_border};
    filter: drop-shadow(0 4px 8px ${({ theme }) => theme.popup?.success});
    color: ${({ theme }) => theme.components.toast__success_text};
    border-radius: 16px;
    bottom: auto;
    opacity: 0;
    top: 120px;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 12px;
    ${({$active}) => $active ? css`
        animation: 0.5s ${fadeIn} linear forwards;
        animation-delay: 0.5s;
    `
    : css`
        animation: 0.5s ${fadeOut} linear forwards;
    `
    }
    ${StyledTitleBox} {
        display: none;
    }
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.green?.[800]};
        animation: 3s ${lineMove} linear forwards;
        animation-delay: 0.5s;
    }
    ${media.md`
        left: ${spacing(212)};
    `}
`;

export const StyledNotificationClose = styled.button`
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCloseIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.components.toast__success_text};
`;

export const StyledAuthIcon = styled(Icon)`
    width: 48px;
    min-width: 48px;
    height: 48px;
    stroke: ${({ theme }) => theme.components.toast__success_text};
    fill: ${({ theme }) => theme.components.toast__success_text};
`;
