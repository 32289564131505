import styled from "styled-components";
import { Icon } from "components/atoms/icon/icon.component";
import { StyledContainer } from "styles/basic.styled";
import { StyledStepBox } from "../verify-profile.styled";
import { StyledButtonPrimary } from "components/atoms/button-link/button-link.styled";
import { media } from "lib/styled-components/variables";

export const StyledDocumentBox = styled(StyledStepBox)`
    position: relative;
`;

export const StyledDocContainer = styled(StyledContainer)`
    ${media.mdMax`
        display: flex;
        flex-direction: column;
    `}
`;

export const StyledInfoList = styled.ul`
    padding: 12px 4px;
    margin: 0;
    display: flex;
    flex-direction: column;
    ${media.mdMax`
        display: none;
    `}
`;

export const StyledInfoItem = styled.li`
    padding: 12px 0;
    display: grid;
    align-items: center;
    grid-template-columns: 24px 1fr;
    gap: 8px;
`;

export const StyledInfoNum = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.base.white};
    background-color: #8050F1;
`;

export const StyledButtonList = styled.div`
    padding: 24px 4px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;
    ${media.mdMax`
        padding: 8px 0 12px;
    `}
`;

export const StyledText = styled.p`
    color: ${({ theme }) => theme.text.muted};
    margin: 0;
    padding: 12px 4px 8px;
    ${media.mdMax`
        order: -1;
    `}
`;

export const StyledTextStep = styled.p`
    color: ${({ theme }) => theme.text.muted};
    margin: 0;
`;

export const StyledStepDoc = styled.div`
    padding: 20px 4px;
`;

export const StyledBoxBottom = styled.div`
    position: fixed;
    bottom: 0;
    padding: 20px 0 64px;
    left: 212px;
    right: 0;
    max-width: calc(100vw - 30px);
    width: 530px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${media.mdMax`
        left: 0;
        padding: 20px 0;
        gap: 20px;
        background-color: rgba(255,255,255,0.8);
        backdrop-filter: blur(32px);
        max-width: 100%;
        width: 100%;
        ${StyledButtonPrimary} {
            width: 530px;
            max-width: calc(100% - 30px);
        }
    `}
`;

export const StyledBoxResidence = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
`;

export const StyledResidencButton = styled.button`
    border-radius: 32px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: ${({ theme }) => theme.components.accent__purple_text};
    background-color: ${({ theme }) => theme.components.accent__purple_bg};
    border: none;
    cursor: pointer;
`;

export const StyledResidenceIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.components.accent__purple_text};
    ${media.mdMax`
        width: 16px;
        height: 16px;
    `}
`;

export const StyledImageList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 12px;
    margin: 12px 0 18px;
`;

export const StyledImagePreview = styled.button`
    max-width: 256px;
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    ${media.mdMax`
        max-width: 154px;
    `}
`;

export const StyledButtonMobile = styled.button`
    display: none;
    background: none;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.text.muted};
    ${media.mdMax`
        display: grid;
        align-items: center;
        gap: 6px;
        grid-template-columns: 20px 1fr;
    `}
`;

export const StyledIconInfo = styled(Icon)`
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.text.muted};
`;
