import type { NextPage, InferGetServerSidePropsType } from "next";
import { GetUserCountry } from "lib/middlewares/get-user-country";
import { getPreRenderingProps } from "lib/pre-rendering.get-props";
import { RedirectSession } from "lib/middlewares/redirect-session";
import { AuthorizationLayout } from "components/organisms/authorization-layout/authorization-layout.component";
import { LoginTemplate } from "components/templates/login/login.template";
import { UserTranslations } from "lib/middlewares/translations";

interface LoginProps {
    loggerData: { [key: string]: string };
}

const Login: NextPage<LoginProps> = ({
    loggerData,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    return (
        <>
            <AuthorizationLayout>
                <LoginTemplate loggerData={loggerData} />
            </AuthorizationLayout>
        </>
    );
};

export const getServerSideProps = getPreRenderingProps(
    GetUserCountry,
    RedirectSession,
    UserTranslations,
);

export default Login;
