import { FC, useState, useEffect } from "react";
import { IDocSample } from "lib/hooks/strapi/getDocuments";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import Image from "next/image";
import { StyledCloseIcon } from "./list-image.styled";
import { IconTypes } from "components/atoms/icon/icon.types";
import { StepDocuments } from "components/templates/verify-profile/documents/documents.component";
import { mixpanelVariant } from "helpers/mixpanel";
import { MixpanelTypes } from "helpers/mixpanel/types";
import {
    StyledListImageModal,
    StyledListImageTitle,
    StyledImagePreview,
    StyledButtonClose,
    StyledSlideItem,
} from "./list-image.styled";
import "swiper/css";
import "swiper/css/pagination";

export interface IProps {
    closeModal: () => void;
    list: IDocSample[];
    start: number;
    step: StepDocuments;
}

interface IList {
    name: string;
    url: string;
    width: string;
    height: string;
}

export const DocListImage: FC<IProps> = ({ closeModal, list, start, step }) => {
    const [ gallery, setGallery ] = useState<IList[]>([]);
    const [ initialSlide, setInitialSlide ] = useState<number>(0);
    const [ startSlider, setStartSlider ] = useState<boolean>(false);

    useEffect(() => {
        let startNum = 0;
        list.map(((item, index, arr) => {
            item.documentImage.map((image) => {
                if(start === image.documentImageFile.data.id) {
                    setInitialSlide(startNum);
                } else {
                    startNum++;
                }
                const galleryItem: IList = {
                    name: image.documentImageLabel ? `${item.documentName} (${image.documentImageLabel})` : item.documentName,
                    url: image.documentImageFile.data.attributes.url,
                    width: image.documentImageFile.data.attributes.width,
                    height: image.documentImageFile.data.attributes.height,
                };
                setGallery((prev) => [...prev, galleryItem]);
            });
            if(index+1 === arr.length) {
                setStartSlider(true);
            }
        }));
    }, []);

    useEffect(() => {
        switch(step) {
            case StepDocuments.IDENTITY: return mixpanelVariant(MixpanelTypes.PROOF_OF_IDENTITY_MODAL);
            case StepDocuments.RESIDENCE: return mixpanelVariant(MixpanelTypes.PROOF_OF_RESIDENCE_MODAL);
        }
    }, [step]);

    const imageUrl = process.env.STRAPI_API?.replace("/api", "");

    return (
        <StyledListImageModal closeModal={closeModal} numberOverlay={0.7}>
            <StyledButtonClose onClick={closeModal}>
                <StyledCloseIcon icon={IconTypes.CLOSE} />
            </StyledButtonClose>
            {startSlider &&
                <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{ clickable: true }}
                    initialSlide={initialSlide}
                >
                    {gallery.map((item) => (
                        <SwiperSlide key={item.name.replace(/ /g, "")}>
                            <StyledSlideItem>
                                <StyledListImageTitle variant={TypographyTypes.H3}>{item.name}</StyledListImageTitle>
                                <StyledImagePreview>
                                    <Image
                                        src={`${imageUrl}${item.url}`}
                                        alt={item.name}
                                        width={item.width}
                                        height={item.height}
                                    />
                                </StyledImagePreview>
                            </StyledSlideItem>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </StyledListImageModal>
    );
};
