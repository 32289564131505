import { FC } from "react";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { useTranslation } from "next-i18next";
import {
    StyledInfoDocuments,
    StyledInfoBox,
    StyledInfoTitle,
    StyledInfoDescription,
    StyledInfoList,
    StyledInfoItem,
    StyledInfoNum,
    StyledButtonList,
    StyledMoreLink,
    StyledLink,
} from "./info-documents.styled";

export interface IProps {
    closeModal: () => void;
}

export const InfoDocuments: FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation();

    const [text1, link, text2] = __("infoDocuments.modalList1").split("{sep}");

    return (
        <StyledInfoDocuments closeModal={closeModal}>
            <StyledInfoBox>
                <StyledInfoTitle variant={TypographyTypes.H3}>{__("infoDocuments.modalTitle")}</StyledInfoTitle>
                <StyledInfoDescription variant={TypographyTypes.DEFAULT}>{__("infoDocuments.modalDescription")}</StyledInfoDescription>
                <Typography variant={TypographyTypes.DEFAULT}>{__("infoDocuments.modalText")}</Typography>
                <StyledInfoList>
                    <StyledInfoItem>
                        <StyledInfoNum>1</StyledInfoNum>
                        <Typography variant={TypographyTypes.DEFAULT}>
                            {text1}
                            <StyledLink href="taxpayersportal.com" target="_blank">{link}</StyledLink>
                            {text2}
                        </Typography>
                    </StyledInfoItem>
                    <StyledInfoItem>
                        <StyledInfoNum>2</StyledInfoNum>
                        <Typography variant={TypographyTypes.DEFAULT}>
                            {__("infoDocuments.modalList2")}
                        </Typography>
                    </StyledInfoItem>
                </StyledInfoList>
                <StyledButtonList>
                    <StyledMoreLink href="/"><Typography variant={TypographyTypes.DEFAULT}>{__("Learn more")}</Typography></StyledMoreLink>
                    <ButtonLink
                        variant={ButtonTypes.SECONDARY}
                        text={__("components.close")}
                        onClick={closeModal}
                    />
                </StyledButtonList>
            </StyledInfoBox>
        </StyledInfoDocuments>
    );
};
