import styled from "styled-components";
import { Modals } from "../modals.component";
import { Typography } from "components/atoms/typography/typography.component";
import { StyledTitleBox } from "../modals.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { media } from "lib/styled-components/variables";

export const StyledListImageModal = styled(Modals)`
    max-width: 570px;
    border-radius: 0;
    background: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: ${({ theme }) => theme.base.white};
    position: relative;
    height: 100%;
    ${StyledTitleBox} {
        display: none;
    }
    .swiper {
        height: 100%;
        .swiper-wrapper {
            max-height: calc(100vh - 60px);
        }
        .swiper-pagination-bullet {
            width: 9px;
            height: 9px;
            background-color: ${({ theme }) => theme.base.white};
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background-color: ${({ theme }) => theme.green[700]};
            }
        }
    }
`;

export const StyledSlideItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const StyledListImageTitle = styled(Typography)`
    padding: 54px 22px 28px;
    margin: 0;
    ${media.mdMax`
        padding-top: 80px;
    `}
`;

export const StyledImagePreview = styled.div`
    max-width: calc(100% - 60px);
    max-height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    ${media.mdMax`
        max-width: calc(100% - 32px);
        overflow-y: auto;
    `}
`;

export const StyledButtonClose = styled.button`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: 20px;
    right: -100px;
    background: none;
    border: none;
    z-index: 2;
    ${media.mdMax`
        right: 0;
    `}
`;

export const StyledCloseIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.base.white};
    fill: ${({ theme }) => theme.base.white};
`;
