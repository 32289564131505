import styled from "styled-components";
import { StyledContainer } from "styles/basic.styled";
import { spacing } from "helpers/conversion-style";

export const StyledStepBox = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: ${spacing(100)};
`;

export const StyledTitleSection = styled.section`
    padding: ${spacing(16)} 0;
`;

export const StyledTitleContainer = styled(StyledContainer)`
    display: grid;
    align-items: center;
    grid-template-columns: 48px 1fr;
    gap: 8px;
`;
