/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes, ButtonSize } from "components/atoms/button-link/button-link.types";
import { useModals } from "components/providers/modals/modals.provider";
import { IDocSample } from "lib/hooks/strapi/getDocuments";
import { mixpanelVariant } from "helpers/mixpanel";
import { MixpanelTypes } from "helpers/mixpanel/types";
import {
    StyledButtonBack,
    StyledButtonIcon,
} from "../questions-step/questions-step.styled";
import {
    StyledTitleSection,
    StyledTitleContainer,
} from "../verify-profile.styled";
import {
    StyledDocumentBox,
    StyledInfoList,
    StyledInfoItem,
    StyledInfoNum,
    StyledButtonList,
    StyledText,
    StyledTextStep,
    StyledStepDoc,
    StyledBoxBottom,
    StyledImageList,
    StyledImagePreview,
    StyledDocContainer,
    StyledButtonMobile,
    StyledIconInfo,
} from "./documents.styled";

interface IProps {
    backStep: () => void;
    nextStep: () => void;
    documents: IDocSample[];
}

interface IInfo {
    text: string;
    key: string;
}

export enum StepDocuments {
    IDENTITY = "Identity",
    RESIDENCE = "Residence",
}

export const DocumentsStep: React.FC<IProps> = ({ backStep, nextStep, documents }) => {
    const [__] = useTranslation();
    const [ stepDoc, setStepDoc ] = useState<StepDocuments>(StepDocuments.IDENTITY);
    const [ docIdentity, setDocIdentity ] = useState<IDocSample[]>([]);
    const [ docResidence, setDocResidence ] = useState<IDocSample[]>([]);
    const { openDocListImageModal, openTextDocumentsModal } = useModals();

    const handlerBack = () => backStep();

    useEffect(() => {
        documents.map((item) => {
            switch(item.documentCategory) {
                case "Proof of identity": return setDocIdentity((prev) => [...prev, item]);
                case "Proof of residence": return setDocResidence((prev) => [...prev, item]);
            }
        });
    }, []);

    useEffect(() => {
        switch(stepDoc) {
            case StepDocuments.IDENTITY: return mixpanelVariant(MixpanelTypes.PROOF_OF_IDENTITY);
            case StepDocuments.RESIDENCE: return mixpanelVariant(MixpanelTypes.PROOF_OF_RESIDENCE);
        }
    }, [stepDoc]);

    const infoList: IInfo[] = [
        {
            text: __("infoDocuments.listText1"),
            key: "document-visible",
        },
        {
            text: __("infoDocuments.listText2"),
            key: "ensure",
        },
        {
            text: __("infoDocuments.listText3"),
            key: "no-copies",
        },
        {
            text: __("infoDocuments.listText4"),
            key: "solid-colored",
        },
    ];

    const imageUrl = process.env.STRAPI_API?.replace("/api", "");

    return (
        <StyledDocumentBox>
            <StyledTitleSection>
                <StyledTitleContainer>
                    <StyledButtonBack onClick={handlerBack}>
                        <StyledButtonIcon icon={IconTypes.BACK} />
                    </StyledButtonBack>
                    <Typography variant={TypographyTypes.H4}>
                        {__("infoDocuments.title")}
                    </Typography>
                </StyledTitleContainer>
            </StyledTitleSection>
            <div>
                <StyledDocContainer>
                    <StyledInfoList>
                        {infoList.map((item, index) => (
                            <StyledInfoItem key={item.key}>
                                <StyledInfoNum>{index+1}</StyledInfoNum>
                                <Typography variant={TypographyTypes.LARGE}>{item.text}</Typography>
                            </StyledInfoItem>
                        ))}
                    </StyledInfoList>
                    <StyledButtonList>
                        <ButtonLink
                            variant={stepDoc === StepDocuments.IDENTITY ? ButtonTypes.BLACK : ButtonTypes.SECONDARY}
                            size={ButtonSize.MINI}
                            onClick={() => setStepDoc(StepDocuments.IDENTITY)}
                            text={__("Proof of Identity")}
                        />
                        <ButtonLink
                            variant={stepDoc === StepDocuments.RESIDENCE ? ButtonTypes.BLACK : ButtonTypes.SECONDARY}
                            size={ButtonSize.MINI}
                            onClick={() => setStepDoc(StepDocuments.RESIDENCE)}
                            text={__("Proof of Residence")}
                        />
                    </StyledButtonList>
                    <StyledText>{__("infoDocuments.description")}</StyledText>
                    {stepDoc === StepDocuments.IDENTITY &&
                        <StyledStepDoc>
                            {docIdentity.map((item, index, arr) =>(
                                <div key={item.documentName.replace(/ /g, "")}>
                                    <StyledTextStep>{item.documentName}</StyledTextStep>
                                    <StyledImageList>
                                        {item.documentImage.map((image) => (
                                            <StyledImagePreview
                                                key={`${item.documentName.replace(/ /g, "")}-${image.documentImageLabel}-${image.id}`}
                                                onClick={() => openDocListImageModal(arr, image.documentImageFile.data.id, stepDoc)}
                                            >
                                                <Image
                                                    src={`${imageUrl}${image.documentImageFile.data.attributes.url}`}
                                                    alt="Tablet image"
                                                    width={image.documentImageFile.data.attributes.width}
                                                    height={image.documentImageFile.data.attributes.height}
                                                />
                                            </StyledImagePreview>
                                        ))}
                                    </StyledImageList>
                                </div>
                            ))}
                        </StyledStepDoc>
                    }
                    {stepDoc === StepDocuments.RESIDENCE &&
                        <StyledStepDoc>
                            {docResidence.map((item, index, arr) =>(
                                <div key={item.documentName.replace(/ /g, "")}>
                                    {/* <StyledBoxResidence>
                                        <StyledTextStep>{__("infoDocuments.textResidence")}</StyledTextStep>
                                        <StyledResidencButton onClick={openInfoDocumentsModal}>
                                            <StyledResidenceIcon icon={IconTypes.INFO} />
                                            <Typography variant={TypographyTypes.DEFAULT}>
                                                {__("infoDocuments.linkObtain")}
                                            </Typography>
                                        </StyledResidencButton>
                                    </StyledBoxResidence> */}
                                    <StyledTextStep>{item.documentName}</StyledTextStep>
                                    <StyledImageList>
                                        {item.documentImage.map((image) => (
                                            <StyledImagePreview
                                                key={`${item.documentName.replace(/ /g, "")}-${image.documentImageLabel}-${image.id}`}
                                                onClick={() => openDocListImageModal(arr, image.documentImageFile.data.id, stepDoc)}
                                            >
                                                <Image
                                                    src={`${imageUrl}${image.documentImageFile.data.attributes.url}`}
                                                    alt="Tablet image"
                                                    width={image.documentImageFile.data.attributes.width}
                                                    height={image.documentImageFile.data.attributes.height}
                                                />
                                            </StyledImagePreview>
                                        ))}
                                    </StyledImageList>
                                </div>
                            ))}
                        </StyledStepDoc>
                    }
                    <StyledBoxBottom>
                        <StyledButtonMobile onClick={openTextDocumentsModal}>
                            <StyledIconInfo icon={IconTypes.INFO} />
                            <Typography variant={TypographyTypes.DEFAULT}>{__("infoDocuments.textUpload")}</Typography>
                        </StyledButtonMobile>
                        <ButtonLink
                            variant={ButtonTypes.PRIMARY}
                            size={ButtonSize.DEFAULT}
                            onClick={nextStep}
                            text={__("infoDocuments.buttonIdentity")}
                        />
                    </StyledBoxBottom>
                </StyledDocContainer>
            </div>
        </StyledDocumentBox>
    );
};
